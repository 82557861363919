$(() => {
  // TNEW Add-on - Sorting Price Types
  const priceTypes = document.querySelectorAll('.tn-plug-in-add-ons-price-types');

  priceTypes.forEach(fieldset => {
      const list = fieldset.querySelector('ul');
      const listItems = Array.from(list.querySelectorAll('.tn-plug-in-add-ons-price-types-item'));

      // Separate member and non-member items
      const memberItems = listItems.filter(item => item.querySelector('label').innerText.startsWith('*Member'));
      const nonMemberItems = listItems.filter(item => !item.querySelector('label').innerText.startsWith('*Member'));

      // Alphabetically sort all the non member items
      nonMemberItems.sort((a, b) => {
          const labelA = a.querySelector('label').innerText.toUpperCase();
          const labelB = b.querySelector('label').innerText.toUpperCase();
          return labelA.localeCompare(labelB);
      });

      // Rebuild the list with member items first, then non-member items
      const sortedItems = [...memberItems, ...nonMemberItems];

      // Empty the existing list so that the sorted version can be listed instead
      list.innerHTML = ''; 

      sortedItems.forEach(item => {
          list.appendChild(item); 
      });
  });
})
